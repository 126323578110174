import { TextButton } from "./textbutton";

export class GameOverPage extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.score = config.score || 0;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.75
            }
        });
        this.back.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.back);
        
        this.lb_gameover = this.scene.add.sprite(this.scene.ScreenWidth / 2, 430, "BackgroundAtlas", "lb_gameover.png").setOrigin(0.5, 0.5);
        this.add(this.lb_gameover);
        this.lb_gameover.setScale(this.config.scale[0], this.scale[1]);

        this.lb_final_score = this.scene.add.bitmapText(this.scene.ScreenWidth / 2, 500, "GameOver", "YOUR FINAL SCORE:", 40, "center").setOrigin(0.5, 0.5);
        this.add(this.lb_final_score);

        this.final_score = this.scene.add.bitmapText(this.scene.ScreenWidth / 2, 530, "GameOver", "0", 85, "center").setOrigin(0.5, 0.5);
        this.add(this.final_score);

        this.btn_play_again = new TextButton(this.scene, {
            x: this.scene.ScreenWidth / 2,
            y: 600,
            atlas: "BackgroundAtlas",
            normal: "lb_play_again.png",
            select: "slb_play_again.png",
            onClick: this.onClickPlayAgain.bind(this)
        });
        this.add(this.btn_play_again);
        this.btn_play_again.setScale(this.config.scale[0], this.config.scale[1]);

        this.btn_leaderboard = new TextButton(this.scene, {
            x: this.scene.ScreenWidth / 2,
            y: 650,
            atlas: "BackgroundAtlas",
            normal: "lb_leaderboard.png",
            select: "slb_leaderboard.png",
            onClick: this.onClickLeaderboard.bind(this)
        });
        this.add(this.btn_leaderboard);
        this.btn_leaderboard.setScale(this.config.scale[0], this.config.scale[1]);
        
    }

    setFinalScore(score) {
        this.score = score;
        this.final_score.setText(score);
    }

    onClickPlayAgain() {
        console.log("play again")
        this.scene.sndclickmenu.play();
        this.scene.initGame(false);
    }

    onClickLeaderboard() {
        this.scene.sndclickmenu.play();
        this.scene.openLeaderBoard();
    }

    onClickShare() {
        this.scene.sndclickmenu.play();
        this.scene.openSharePage();
    }
}