import CommonDef from '../def/commondef.js';

export class LoadingScene extends Phaser.Scene {
    constructor(){
        super({
            key:"LoadingScene",
        })
    }

    preload(){
        this.progressBarWidth = 500;
        this.progressBarHeight = isMobile ? 40 : 30;

        this.initGraph();

        this.load.atlas("BackgroundAtlas", "images/BackgroundAtlas.png", "images/BackgroundAtlas.json");
        this.load.atlas("CarAtlas", "images/CarAtlas.png", "images/CarAtlas.json");
        this.load.atlas("ActorAtlas", "images/ActorAtlas.png", "images/ActorAtlas.json");
        this.load.atlas("CrowdAtlas", "images/CrowdAtlas.png", "images/CrowdAtlas.json");
        this.load.atlas("NPC9", "images/npc9.png", "images/npc9.json");
        this.load.atlas("ActorWalk", "images/ActorWalk.png", "images/ActorWalk.json");
        this.load.atlas("Derbis", "images/derbis.png", "images/derbis.json");
        this.load.atlas("Smoke", "images/smoke.png", "images/smoke.json");

        this.load.image("GameStart", "images/game_start.png");
        this.load.image("GameBackground", "images/background.png");
        this.load.image("GameControl", "images/control_page.png");
        this.load.image("TimeTitle", "images/time_title.png");

        this.load.audio("Music","audio/music.mp3");
        this.load.audio("GameOver","audio/game_over.mp3");
        this.load.audio("Fight","audio/fight.mp3");
        this.load.audio("CrowdCheering","audio/crowd_cheering.mp3");
        this.load.audio("ClickMenu","audio/click_menu.mp3");
        this.load.audio("CarSmash1","audio/car_smash_1.mp3");
        this.load.audio("CarSmash2","audio/car_smash_2.mp3");
        this.load.audio("CarSmash3","audio/car_smash_3.mp3");
        this.load.audio("BonusStage","audio/bonus_stage.mp3");
        this.load.audio("Jump","audio/jump.mp3");

        this.load.plugin('rexinputtextplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexinputtextplugin.min.js', true);    

        this.load.on("progress", (percent) => {
            if(this.progressBar) {
                this.progressBar.clear();
                this.progressBar.fillStyle(0xff01ff, 1);
                this.progressBar.fillRect(-this.progressBarWidth / 2, 0, this.progressBarWidth * percent, this.progressBarHeight);
            }
            this.progressText && this.progressText.setText(Math.round(percent * 100) + "%");
        });
    }

    create() {
        this.scene.start("GameScene");
    }

    initGraph() {

        this.backContainer = this.add.container(0,0);
        this.ScreenWidth = CommonDef.ScreenSize.width;
        this.ScreenHeight = CommonDef.ScreenSize.height;
        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "portrait" : "landscape";
        this.cameras.main.setZoom(2.5);

        this.logo = this.add.sprite(this.ScreenWidth / 2, 500, "Logo").setOrigin(0.5, 0.5);
        this.backContainer.add(this.logo);
        this.logo.setScale(0.6);

        this.progressBack = this.add.graphics({
            x: this.ScreenWidth / 2,
            y: 700,
            fillStyle: {
                color: 0x222222,
                alpha: 1
            }
        });
        this.progressBack.fillRect(-this.progressBarWidth / 2, 0, this.progressBarWidth, this.progressBarHeight);
        this.backContainer.add(this.progressBack);

        this.progressBar = this.add.graphics({
            x: this.ScreenWidth / 2,
            y: 700
        });

        this.progressText = this.add.bitmapText(this.ScreenWidth / 2, 690, "GameOver", "30", 75, "center").setOrigin(0.5, 1);
        this.backContainer.add(this.progressText);
    }
}