export default {
    ScreenSize: {
        width: 1920,
        height: 1080
    },
    ClientSize: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
    },
    Car: {
        x: [955, 1070, 841],
        y: [720, 720, 720]
    },
    Wheel: {
        x: 955,
        y: 720
    },
    Car_Shadow: {
        x: 950,
        y: 725
    },
    Umbrella: {
        x: 645,
        y: 753
    },
    Actor: {
        x: [830, 1120, 975],
        y: [725, 725, 665]
    },
    Blow: {
        x: [855, 985],
        y: [675, 651]
    },
    Bat: {
        x: 840,
        y: 655
    },
    Crowd: {
        x: [1040, 905, 930, 825, 850, 865, 1000, 770, 750, 790, 875, 888, 955, 980, 1024, 1060, 1080, 1100, 1045, 1005],
        y: [720, 698, 670, 680, 710, 660, 680, 665, 680, 670, 650, 650, 675, 677, 700, 675, 677, 680, 655, 705]
    },
    CrowdScale: [1, 0.65, 1, 0.7, 0.7, 1, 1, 1, 1, 1, 1, 1, 1.1, 1, 1, 1, 1.1, 1.15, 1, 1],
    CrowdDepth: [11, 10, 10, 11, 10, 9, 10, 10, 10, 9, 8, 9, 10, 8, 8, 10, 10, 10, 7, 10],
    CrowdFlip: [false, false, false, true, false, false, true, false, false, false, false, false, false, true, false, false, false, false, true, true],
    HealthBar: {
        x: 860,
        y: 380
    },
    Score: {
        x: 1120,
        y: [365, 395]
    },
    Time: {
        x: 1225,
        y: [365, 395]
    },

    btn_ko: {
        x: 1130,
        y: 690
    },
    btn_jump: {
        x: 1210,
        y: 630
    },
    btn_direct: {
        x: 710,
        y: 680
    },
    GameState: {
        None: -1,
        Preparing: 0,
        ShowContols: 1,
        PickingBar: 2,
        Playing: 3,
        Win: 4,
        Smoke: 5,
        Lose: 6,
        GameOver: 7
    },
    ActorState: {
        BeforeBar: 0,
        PickedBar: 1,
        Jump: 2,
        JumpedCar: 3,
        Hitting: 4,
        GameOver: 5
    },
    Animations: {
        Actor0: { atlas: "ActorAtlas", prefix: "THUG_NOBAT", start: 1, end: 8, repeat: -1, speed: 0.15, zeroPad: 0 },
        Actor1: { atlas: "ActorAtlas", prefix: "THUG_BATSTANCE", start: 1, end: 6, repeat: -1, speed: 0.15, zeroPad: 0 },
        Hit: { atlas: "ActorAtlas", prefix: "BATSWING", start: 1, end: 5, repeat: 0, speed: 0.15, zeroPad: 0 },
        Smoke: { atlas: "ActorAtlas", prefix: "THUGSMOKE", start: 1, end: 7, repeat: 0, speed: 0.3, zeroPad: 0 },
        Jump: { atlas: "ActorAtlas", prefix: "thugjump", start: 1, end: 4, repeat: 0, speed: 0.15, zeroPad: 0 },
        Blow: { atlas: "Smoke", prefix: "THUGSMOKE", start: 1, end: 8, repeat: 0, speed: 0.1, zeroPad: 0 },
        Walk: { atlas: "ActorWalk", prefix: "WALK", start: 1, end: 8, repeat: -1, speed: 0.06, zeroPad: 0 },

        Gunna_stance: { atlas: "CrowdAtlas", prefix: "GUNNA_STANCE", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd0: { atlas: "CrowdAtlas", prefix: "GUNNA_BAT", start: 1, end: 4, repeat: 0, speed: 0.1, zeroPad: 0 },
        Crowd1: { atlas: "CrowdAtlas", prefix: "NPC1_", start: 1, end: 4, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd2: { atlas: "CrowdAtlas", prefix: "NPC2_", start: 1, end: 6, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd3: { atlas: "CrowdAtlas", prefix: "NPC3_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd4: { atlas: "CrowdAtlas", prefix: "NPC4_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd5: { atlas: "CrowdAtlas", prefix: "NPC5_", start: 1, end: 4, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd6: { atlas: "CrowdAtlas", prefix: "OG6_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd7: { atlas: "CrowdAtlas", prefix: "NPC7_", start: 1, end: 4, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd8: { atlas: "CrowdAtlas", prefix: "NPC8_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd9: { atlas: "NPC9", prefix: "NPC9_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd10: { atlas: "CrowdAtlas", prefix: "NPC10_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd11: { atlas: "CrowdAtlas", prefix: "NPC11_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd12: { atlas: "CrowdAtlas", prefix: "NPC12_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd13: { atlas: "CrowdAtlas", prefix: "NPC13_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd14: { atlas: "CrowdAtlas", prefix: "NPC14_", start: 1, end: 4, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd15: { atlas: "CrowdAtlas", prefix: "NPC15_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd16: { atlas: "CrowdAtlas", prefix: "NPC16_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd17: { atlas: "CrowdAtlas", prefix: "NPC17_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd18: { atlas: "CrowdAtlas", prefix: "NPC18_", start: 1, end: 3, repeat: -1, speed: 0.15, zeroPad: 0 },
        Crowd19: { atlas: "CrowdAtlas", prefix: "TAURUS_STANCE", start: 1, end: 5, repeat: -1, speed: 0.15, zeroPad: 0 },
    }
}