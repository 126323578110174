import { TextButton } from "../../components/textbutton";
import CommonDef from "../def/commondef"

export class NameScene extends Phaser.Scene {

    constructor(){
        super({
            key:"NameScene",
        })
    }

    preload(){

    }

    create(){

        this.ScreenWidth = CommonDef.ScreenSize.width;
        this.ScreenHeight = CommonDef.ScreenSize.height;

        this.back = this.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 0.8
            }
        });
        this.back.fillRect(0, 0, this.ScreenWidth, this.ScreenHeight);

        this.title = this.add.sprite(this.ScreenWidth / 2, 380, "BackgroundAtlas", "lb_enter_nickname.png").setOrigin(0.5, 0.5).setScale(0.8);

        this.description1 = this.add.bitmapText(this.ScreenWidth / 2, 480, "GameOver", "OTHER PLAYERS WILL READ THIS", 90, "center").setOrigin(0.5, 0.5);

        this.description2 = this.add.bitmapText(this.ScreenWidth / 2, 520, "GameOver", "ON THE LEADERBOARD", 90, "center").setOrigin(0.5, 0.5);
        
        this.btn_submit = new TextButton(this, {
            x: this.ScreenWidth / 2,
            y: 860,
            atlas: "BackgroundAtlas",
            normal: "lb_submit.png",
            select: "slb_submit.png",
            onClick: this.onClickSubmit.bind(this)
        }).setScale(0.8);

        let paddingBottom = "5px"
        let paddingTop = "0px"
        if(isMobile){
            paddingBottom = "0px"
            paddingTop = "0px"
        }
        this.nickname_field = this.add.rexInputText(this.ScreenWidth / 2, 670, 800, 80, {
            type: 'text',
            text: '',
            align: 'center',
            fontFamily: 'Lo-Res',
            fontSize: '55px',
            color: '#000000',
            placeholder: "ENTER YOUR NICKNAME HERE",
            backgroundColor: '0xffffff',
            paddingBottom: paddingBottom,
            paddingTop: paddingTop,
        })
        this.nickname_field.on('textchange', function(inputText, e){
            this.nickname_field.text = this.slicestring(inputText.text).toUpperCase();
        },this);

        this.sndclickmenu = this.sound.add('ClickMenu',{loop: false,volume: 1});

    }

    update(){

    }

    onClickSubmit() {
        this.sndclickmenu.play();
        setTimeout(()=>{
            window.nickname = this.nickname_field.text;
            this.scene.stop();
            this.scene.get('GameScene').game_start_page.setVisible(true);
        },1000);
    }

    slicestring(str){
        str = str.slice(0,10);
        return str;
    }

}