import gameConfig from './gameconfig.js';
import CommonDef from '../def/commondef.js';

class Game extends Phaser.Game {
    constructor(){
        super(window.isMobile ? gameConfig.mobile : gameConfig.pc)
    }
}

window.nickname = ""

window.onload = function(){
    window.game = new Game();
    window.addEventListener('resize', _ => {
        //resize();
    })
    //resize();
}

const resize = () => {

    if(!window.isMobile) return;
    let width = document.documentElement.clientWidth
    let height = document.documentElement.clientHeight

    if(CommonDef.orientation == "portrait" && Math.abs(width - height) < 300) {
        return;
    }
    if(CommonDef.orientation == "landscape" && width > height * 3) {
        return;
    }

    let w = width;
    let h = height;
    CommonDef.ClientSize.width = width;
    CommonDef.ClientSize.height = height;
    let orientation = width > height ? "landscape" : "portrait";

    if(orientation != CommonDef.orientation) {
        CommonDef.orientation = orientation; 
        
        if(width > height) {
            game.scale.resize(CommonDef.ScreenSize.width, CommonDef.ScreenSize.height);
            if(w / h > CommonDef.ScreenSize.width / CommonDef.ScreenSize.height) {
                w = h * CommonDef.ScreenSize.width / CommonDef.ScreenSize.height;
            } else {
                h = w * CommonDef.ScreenSize.height / CommonDef.ScreenSize.width
            }
        } else {
            game.scale.resize(CommonDef.ScreenSize.width, CommonDef.ScreenSize.height);
            if(w / h > CommonDef.ScreenSize.width / CommonDef.ScreenSize.height) {
                w = h * CommonDef.ScreenSize.width / CommonDef.ScreenSize.height;
            } else {
                h = w * CommonDef.ScreenSize.height / CommonDef.ScreenSize.width;
            }
        }

        game.canvas.style.width = w + 'px'
        game.canvas.style.height = h + 'px'
        game.canvas.style.position = "absolute";
        game.canvas.style.top = (height - h) / 2 + 'px'
        game.canvas.style.left = (width - w) / 2 + 'px'
        game.canvas.style.marginTop = '0px !important'
        game.canvas.style.marginLeft = '0px !important';
        game.scale.refresh();
        CommonDef.currentScene && CommonDef.currentScene.create();
    }
}
