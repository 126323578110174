export class GameNet {
    constructor(scene) {
        this.scene = scene;
    }

    getUsers() {
        this.sendRequest("POST", "https://space47.herokuapp.com/apiusers/", {}, (users) => {
            this.scene.setLeaderBoardPlayers(users)
        })
    }

    createUser(username, score) {
        this.sendRequest("POST", "https://space47.herokuapp.com/apicreateuser/", {
            username: username,
            score: score
        }, (user) => {
            if(!user.id) {
                this.updateUser(username, score);
            } else {
                this.scene.successCreateUser();
            }
        })
    }

    updateUser(username, score) {
        this.sendRequest("POST", "https://space47.herokuapp.com/apiuserupdate/", {
            username: username,
            score: score
        }, (user) => {
            //
        });
    }

    sendRequest(method, url, params, callback) {
        $.ajax({
            url: url,
            method: method,
            data: params
        }).done((response) => {
            callback(response);
        })
    }
}