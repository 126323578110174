import { TextButton } from "./textbutton";

export class LeaderBoardPage extends Phaser.GameObjects.Container {
    constructor(scene) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.initGraph();
    }

    initGraph() {

        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.back.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.back);
        this.title = this.scene.add.sprite(this.scene.ScreenWidth / 2, 380, "BackgroundAtlas", "lb_leaderboard.png").setOrigin(0.5, 0.5);
        this.add(this.title);
        this.title.setScale(0.6);

        this.btn_back = new TextButton(this.scene, {
            x: this.scene.ScreenWidth / 2,
            y: 700,
            atlas: "BackgroundAtlas",
            normal: "lb_back.png",
            select: "slb_back.png",
            onClick: this.onClickBack.bind(this)
        });
        this.add(this.btn_back);
        this.btn_back.setScale(0.6);

        this.playerListContainer = this.scene.add.container(0, 0);
        this.add(this.playerListContainer);

        this.loadinglabel = this.scene.add.bitmapText(0, 0, "GameOver", ( "Downloading.." ), 60, "center").setOrigin(0.5);
        this.loadinglabel.x = this.scene.ScreenWidth * 0.5;
        this.loadinglabel.y =  this.scene.ScreenHeight * 0.5;
        this.loadinglabel.setVisible(true);
        this.add(this.loadinglabel);
    }

    onClickBack() {
        this.scene.sndclickmenu.play();
        this.loadinglabel.setVisible(true);
        this.setVisible(false);
    }

    setPlayers(players) {
        this.playerListContainer.removeAll(true);
        if(!players || players.length < 1) return;
        this.loadinglabel.setVisible(false);
        for(let i = 0 ; i < players.length ; i ++) {
            if(i > 9) break;
            let player = this.scene.add.container(775, 26 * i + 410);
            let playername = this.scene.add.bitmapText(0, 0, "GameOver", ( (i + 1) + ". " + players[i].name ), 40, "center").setOrigin(0, 0);
            player.add(playername)
            let score = this.scene.add.bitmapText(368, 0, "GameOver", players[i].score, 40, "center").setOrigin(1, 0);
            player.add(score);
            this.playerListContainer.add(player);
        }
    }
}