import { TextClass } from "./text";

export class BreakPoint extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        // this.signGraph = this.scene.add.graphics({
        //     x: 0,
        //     y: 0,
        //     fillStyle: {
        //         color: 0xff0000,
        //         alpha: 1
        //     }
        // })
        // this.signGraph.fillTriangle(0, 0, 15, -15, -15, -15);
        // this.signGraph.fillRect(-8, -20, 16, 5);
        // this.add(this.signGraph);
        // this.text1 = TextClass.plainText1(this.scene, {
        //     x: 0,
        //     y: -45,
        //     text: "BREAK",
        //     color: "#ff0000",
        //     fontSize: 12
        // }).setOrigin(0.5, 0.5);
        // this.add(this.text1);
        // this.text2 = TextClass.plainText1(this.scene, {
        //     x: 0,
        //     y: -30,
        //     text: "POINT",
        //     color: "#ff0000",
        //     fontSize: 12
        // }).setOrigin(0.5, 0.5);
        // this.add(this.text2);

        this.img = this.scene.add.sprite(0, 0, "BackgroundAtlas", "break_point.png").setOrigin(0.5, 1);
        this.add(this.img);
        this.img.setScale(0.2)
    }
}