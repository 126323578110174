import { PreloadingScene } from '../scenes/preloading.js';
import { LoadingScene } from '../scenes/loading.js';
import { GameScene } from '../scenes/game.js';
import { NameScene } from '../scenes/name.js';

export default {
    pc: {
        type: Phaser.WEBGL,
        width: 1920,
        height: 1080,
        parent : 'main',
        scene:[
            PreloadingScene, 
            LoadingScene, 
            GameScene,
            NameScene
        ],
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            orientation: Phaser.Scale.Orientation.LANDSCAPE
        },
        dom: {
            createContainer: true
        },
        fps: 60
    },
    mobile: {
        type: Phaser.CANVAS,
        parent : 'main',
        width: 1920,
        height: 1080,
        scene:[
            PreloadingScene, 
            LoadingScene, 
            GameScene,
            NameScene
        ],
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            orientation: Phaser.Scale.Orientation.LANDSCAPE
        },
        dom: {
            createContainer: true
        },
        fps: 60
    }
};