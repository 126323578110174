export class TextClass {
    static plainText1(scene, config) {
        let textObject = scene.add.text(config.x, config.y, config.text, {
            fontFamily: config.fontFamily || "GameFont",
            fontSize: config.fontSize + "px",
            color: config.color,
            // fontStyle: config.fontStyle || "bold"
        });
        if(config.gradient && config.gradient.length > 1) {
            let gradient = textObject.context.createLinearGradient(0, 0, 0, textObject.height);
            for(let i = 0 ; i < config.gradient.length ; i++) {
                gradient.addColorStop(i, config.gradient[i]);
            }
            textObject.setFill(gradient)
        }
        return textObject;
    }
}