export class TextButton extends Phaser.GameObjects.Container {
    constructor(scene, config) {
        super(scene, config.x, config.y);
        scene.add.existing(this);
        this.scene = scene;
        this.config = config;
        this.initGraph();
    }

    initGraph() {
        this.text = this.scene.add.sprite(0, 0, this.config.atlas, this.config.normal).setOrigin(0.5, 0.5);
        this.add(this.text);
        this.text.setInteractive();
        this.text.on("pointerover", () => {
            this.text.setTexture(this.config.atlas, this.config.select);
        });
        this.text.on("pointerdown", () => {
            this.text.setTexture(this.config.atlas, this.config.select);
        });
        this.text.on("pointerup", () => {
            this.config.onClick && this.config.onClick();
        })
        this.text.on("pointerout", () => {
            this.text.setTexture(this.config.atlas, this.config.normal);
        });
    }
}