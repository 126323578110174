export class PreloadingScene extends Phaser.Scene {
    constructor(){
        super({
            key:"PreloadingScene",
        })
    }

    preload(){
        this.load.image("Logo", "images/logo.png");
        this.load.bitmapFont("GameOver","font/gameover.png","font/gameover.xml");
    }

    create() {
        this.scene.start("LoadingScene");
    }
}