import { BreakPoint } from '../../components/breakpoint.js';
import { GameOverPage } from '../../components/gameoverpage.js';
import { LeaderBoardPage } from '../../components/leaderboard.js';
import { SharePage } from '../../components/sharepage.js';
import { TextClass } from '../../components/text.js';
import CommonDef from '../def/commondef.js';
import { GameNet } from '../net/game_net.js';

export class GameScene extends Phaser.Scene {
    constructor(){
        super({
            key:"GameScene",
        })
    }

    preload(){
        
    }

    create() {

        this.initAnimation();
        this.initGraph();
        this.initSound();
        this.initGame(true);
        this.gameNet = new GameNet(this);
        
    }

    initGraph() {

        this.ScreenWidth = CommonDef.ScreenSize.width;
        this.ScreenHeight = CommonDef.ScreenSize.height;

        this.orientation = (window.isMobile && document.documentElement.clientWidth < document.documentElement.clientHeight) ? "portrait" : "landscape";
        this.cameras.main.setZoom(2.5);

        this.backContainer = this.add.container(0, 0);

        this.back = this.add.image(this.ScreenWidth / 2, this.ScreenHeight / 2, "GameBackground").setOrigin(0.5, 0.5).setScale(0.33,0.39);
        this.back.width = 634, this.back.height = 423
        this.backContainer.add(this.back);
        this.back.setDepth(0);

        this.breakpoints = [];
        this.break_position = [
            { x: 900, y: 610 },
            { x: 990, y: 610 }
        ];
        for(let i = 0 ; i < this.break_position.length ; i ++) {
            let breakpoint = new BreakPoint(this, {
                x: this.break_position[i].x,
                y: this.break_position[i].y
            });
            this.breakpoints.push(breakpoint);
            this.backContainer.add(breakpoint);
            breakpoint.setDepth(101);
            breakpoint.setVisible(false);
        }

        this.crowdList = [];
        for(let i = 0 ; i < 20 ; i ++) {
            let animation = CommonDef.Animations["Crowd" + i];
            let crowd = this.add.sprite(CommonDef.Crowd.x[i], CommonDef.Crowd.y[i], "CrowdAtlas", animation.prefix + animation.start + ".png").setOrigin(0.5, 1);
            this.backContainer.add(crowd);
            crowd.setDepth(CommonDef.CrowdDepth[i] || 0);
            this.crowdList.push(crowd);
            crowd.setScale(CommonDef.CrowdScale[i]);
            if(CommonDef.CrowdFlip[i]) crowd.setFlipX(true);
            if(i == 0) {
                crowd.anims.play("Gunna_stance");
            } else {
                crowd.anims.play("Crowd" + i);
            }
        }

        this.umbrella = this.add.sprite(CommonDef.Umbrella.x, CommonDef.Umbrella.y, "BackgroundAtlas", "BACKGROUND1_umbrella.png").setOrigin(0, 1);
        this.backContainer.add(this.umbrella);
        this.umbrella.setDepth(100);

        this.CardShadow = this.add.sprite(CommonDef.Car_Shadow.x, CommonDef.Car_Shadow.y, "CarAtlas", "rolls_SHADOW.png").setOrigin(0.5, 1);
        this.backContainer.add(this.CardShadow);
        this.CardShadow.setDepth(100);
        this.CardShadow.setAlpha(0.5);

        this.wheel = this.add.sprite(CommonDef.Wheel.x, CommonDef.Wheel.y, "CarAtlas", "rolls_FINAL_WHEELS.png").setOrigin(0.5, 1);
        this.backContainer.add(this.wheel);
        this.wheel.setDepth(100);

        this.car_left = this.add.sprite(CommonDef.Car.x[1], CommonDef.Car.y[1], "CarAtlas", "car_left_0.png").setOrigin(1, 1);
        this.backContainer.add(this.car_left);
        this.car_left.setDepth(100);

        this.car_right = this.add.sprite(CommonDef.Car.x[2], CommonDef.Car.y[2], "CarAtlas", "car_right_0.png").setOrigin(0, 1);
        this.backContainer.add(this.car_right);
        this.car_right.setDepth(100);

        this.car = this.add.sprite(CommonDef.Car.x[0], CommonDef.Car.y[0], "CarAtlas", "car_front_0.png").setOrigin(0.5, 1);
        this.backContainer.add(this.car);
        this.car.setDepth(100);
        this.car.setVisible(false);

        this.actor = this.add.sprite(CommonDef.Actor.x[0], CommonDef.Actor.y[0], "ActorAtlas", "THUG_NOBAT1.png").setOrigin(0.5, 1);
        this.backContainer.add(this.actor);
        this.actor.setDepth(100);
        this.actor.on('animationcomplete', () => {
            this.actor.setVisible(true);
        });
        
        this.bat = this.add.sprite(CommonDef.Bat.x, CommonDef.Bat.y, "ActorAtlas", "bat.png").setOrigin(0.5, 1);
        this.backContainer.add(this.bat);
        this.bat.setDepth(100);
        this.bat.angle = 150;

        this.blow = this.add.sprite(CommonDef.Blow.x[0], CommonDef.Blow.y[0], "ActorAtlas", "BLOW1.png").setOrigin(0.5, 1);
        this.backContainer.add(this.blow);
        this.blow.setDepth(100);
        this.blow.setVisible(false);

        this.backContainer.sort("depth");

        this.menuContainer = this.add.container(0, 0);
        this.menuContainer.setDepth(1);

        this.game_start_page = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, "GameStart").setOrigin(0.5, 0.5);
        this.menuContainer.add(this.game_start_page);

        let maxscale = Math.max(this.back.width / this.game_start_page.width, this.back.height / this.game_start_page.height)
        this.game_start_page.setScale(maxscale);
        this.game_start_page.setVisible(false);
        
        this.game_control_page = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, "GameControl").setOrigin(0.5, 0.5);
        this.menuContainer.add(this.game_control_page);
        this.game_control_page.setScale(maxscale);
        this.game_control_page.setVisible(false);

        this.game_over_page = new GameOverPage(this, {
            scale: [maxscale] //[this.back.width / this.game_start_page.width, this.back.height / this.game_start_page.height]
        });
        this.menuContainer.add(this.game_over_page);

        //this.menuContainer.setVisible(false);

        this.health_bar = this.add.sprite(CommonDef.HealthBar.x, CommonDef.HealthBar.y, "BackgroundAtlas", "health_bar.png").setOrigin(0.5, 0.5);
        this.backContainer.add(this.health_bar);
        this.health_bar.setScale(this.back.width / this.game_start_page.width, this.back.height / this.game_start_page.height);

        this.carHealthGraph = this.add.graphics({
            x: 876,
            y: 359,
            fillStyle: {
                color: 0xff01ff,
                alpha: 1
            }
        });

        this.score_value = this.add.bitmapText(CommonDef.Score.x, CommonDef.Score.y[1] - 20, "GameOver", "0", 60, "center").setOrigin(0.5, 0.5);
        this.backContainer.add(this.score_value);

        this.time_label = this.add.image(CommonDef.Time.x, CommonDef.Time.y[0], 'TimeTitle').setOrigin(0.5).setScale(0.35)
        this.backContainer.add(this.time_label);

        this.time_value = this.add.bitmapText(CommonDef.Time.x, CommonDef.Score.y[1] - 5, "GameOver", "30", 60, "center").setOrigin(0.5, 0.5);
        this.backContainer.add(this.time_value);

        this.start_label = this.add.sprite(this.ScreenWidth / 2, this.ScreenHeight / 2, "BackgroundAtlas", "lb_start.png").setOrigin(0.5, 0.5);
        this.backContainer.add(this.start_label);
        this.start_label.setScale(this.back.width / this.game_start_page.width, this.back.height / this.game_start_page.height);
        this.start_label.setVisible(false);

        this.derbis_sprite = this.add.sprite(this.car_left.x, this.car_left.y, "Derbis", "part1.png").setOrigin(0.5, 0.5).setScale(1.2);
        this.derbis_sprite.setAlpha(0);
        this.backContainer.add(this.derbis_sprite);

        this.leaderBoard = new LeaderBoardPage(this);
        this.menuContainer.add(this.leaderBoard);
        this.leaderBoard.setVisible(false);

        this.sharePage = new SharePage(this);
        this.menuContainer.add(this.sharePage);
        this.sharePage.setVisible(false);

        if(isMobile) {
            this.input.addPointer(2);
            this.directBtn_xPos = 0;
            this.directBtn_yPos = 0;
            this.btn_ko = this.add.sprite(CommonDef.btn_ko.x, CommonDef.btn_ko.y, "BackgroundAtlas", "btn_ko.png").setOrigin(0.5, 0.5);
            this.backContainer.add(this.btn_ko);
            this.btn_ko.setScale(this.back.width / this.game_start_page.width);
            this.btn_ko.setInteractive();
            this.btn_ko.on("pointerdown", () => {
                this.btn_ko.setAlpha(0.5);
            })
            this.btn_ko.on("pointerup", () => {
                this.btn_ko.setAlpha(1);
                this.gameState == CommonDef.GameState.Playing && this.hit();
            });

            this.btn_jump = this.add.sprite(CommonDef.btn_jump.x, CommonDef.btn_jump.y, "BackgroundAtlas", "btn_jump.png").setOrigin(0.5, 0.5);
            this.backContainer.add(this.btn_jump);
            this.btn_jump.setScale(this.back.width / this.game_start_page.width);
            this.btn_jump.setInteractive();
            this.btn_jump.on("pointerdown", () => {
                this.btn_jump.setAlpha(0.5);
            });

            this.btn_jump.on("pointerup", () => {
                this.btn_jump.setAlpha(1);
                this.gameState == CommonDef.GameState.Playing && this.jump();
            });

            this.direct_back = this.add.sprite(CommonDef.btn_direct.x, CommonDef.btn_direct.y, "BackgroundAtlas", "direct_back.png").setOrigin(0.5, 0.5);
            this.backContainer.add(this.direct_back);
            this.direct_back.setScale(this.back.width / this.game_start_page.width);
            this.btn_direct = this.add.sprite(CommonDef.btn_direct.x, CommonDef.btn_direct.y, "BackgroundAtlas", "direct_btn.png").setOrigin(0.5, 0.5);
            this.backContainer.add(this.btn_direct);
            this.btn_direct.setScale(this.back.width / this.game_start_page.width);
            this.direct_back.setInteractive();
            this.direct_action_area = this.add.graphics({
                x: CommonDef.btn_direct.x,
                y: CommonDef.btn_direct.y,
                fillStyle: {
                    color: 0x000000,
                    alpha: 0.8
                }
            });
            this.backContainer.add(this.direct_action_area);
            let direct_action_rect = new Phaser.Geom.Circle(0, 0, 200);
            this.direct_action_area.setInteractive(direct_action_rect, Phaser.Geom.Circle.Contains);
            // this.direct_action_area.fillCircleShape(direct_action_rect);
            this.direct_action_area.setDepth(102);
            this.direct_action_area.on("pointerdown", (e) => {
                this.direct_btn_on = true;
                this.directBtn_xPos = Math.max(Math.min(e.position.x, 330 + 60), 330 - 60);
                this.directBtn_yPos = Math.min(Math.max(e.position.y, 884 - 60), 884 + 60);
            });
            this.direct_action_area.on("pointermove", (e) => {
                if(!this.direct_btn_on) return;
                this.directBtn_xPos = Math.max(Math.min(e.position.x, 330 + 60), 330 - 60);
                this.directBtn_yPos = Math.min(Math.max(e.position.y, 884 - 60), 884 + 60);
            });
            this.direct_action_area.on("pointerout", () => {
                this.direct_btn_on = false;
                this.movingDirect = "";
                this.tweens.add({
                    targets: this.btn_direct,
                    x: CommonDef.btn_direct.x,
                    y: CommonDef.btn_direct.y,
                    duration: 100,
                    ease: "Power2"
                })
            });
            this.direct_action_area.on("pointerup", () => {
                this.direct_btn_on = false;
                this.movingDirect = "";
                this.tweens.add({
                    targets: this.btn_direct,
                    x: CommonDef.btn_direct.x,
                    y: CommonDef.btn_direct.y,
                    duration: 100,
                    ease: "Power2"
                })
            })

            this.game_start_page.setInteractive();
            this.game_start_page.on("pointerdown", () => {
                if(this.gameState == CommonDef.GameState.Preparing) {
                    if(this.game_start_page.visible){
                        this.prepareGame();
                    }
                }
            })
        } else {
            this.input.keyboard.on('keydown', (event) => {
                if(this.gameState == CommonDef.GameState.Preparing) {
                    if(this.game_start_page.visible){
                        this.showControls();
                    }
                } else if(this.gameState == CommonDef.GameState.ShowContols) {
                    if(this.game_control_page.visible){
                        this.prepareGame();
                    }
                } else if(this.gameState == CommonDef.GameState.Playing && !this.gameend) {
                    if(event.key == "ArrowUp") {
                        this.jump();
                    } else if(event.key == "ArrowDown") {
                        //
                    } else if(event.key == "ArrowLeft") {
                        this.movingDirect = "left";
                    } else if(event.key == "ArrowRight") {
                        this.movingDirect = "right";
                    }
                }
            });
            this.input.keyboard.on('keyup', (event) => {
                if(this.gameState == CommonDef.GameState.Playing && !this.gameend) {
                    if(event.key == "ArrowLeft") {
                        this.movingDirect = "";
                    } else if(event.key == "ArrowRight") {
                        this.movingDirect = "";
                    }else if(event.key == "a" || event.key == "A") {
                        this.hit();
                    } 
                }
            })
        }
    }

    initSound() {
        this.sndbgmusic = this.sound.add('Music',{loop: true,volume: 0.65});
        this.sndgameover = this.sound.add('GameOver',{loop: false,volume: 1});
        this.sndfight = this.sound.add('Fight',{loop: false,volume: 1});
        this.sndcheering = this.sound.add('CrowdCheering',{loop: false,volume: 0.65});
        this.sndclickmenu = this.sound.add('ClickMenu',{loop: false,volume: 1});
        this.sndcarsmash1 = this.sound.add('CarSmash1',{loop: false,volume: 1});
        this.sndcarsmash2 = this.sound.add('CarSmash2',{loop: false,volume: 1});
        this.sndcarsmash3 = this.sound.add('CarSmash3',{loop: false,volume: 1});
        this.sndjump = this.sound.add('Jump',{loop: false,volume: 1});
        this.sndbonusstage = this.sound.add('BonusStage',{loop: false,volume: 1});
    }

    initAnimation() {
        for(let i = 0 ; i < Object.keys(CommonDef.Animations).length ; i ++) {
            let key = Object.keys(CommonDef.Animations)[i];
            let config = CommonDef.Animations[key];
            let frames = this.anims.generateFrameNames(config.atlas, {
                start: config.start,
                end: config.end,
                zeroPad: config.zeroPad,
                prefix: config.prefix,
                suffix: '.png',
            });
            this.anims.create({
                key: key,
                frames: frames,
                frameRate: 1 / config.speed,
                repeat: config.repeat,
                hideOnComplete: true
            });
        }
    }

    initGame(flag) {
        this.actor.setFlipX(false);
        this.gameState = CommonDef.GameState.None;
        this.actorState = CommonDef.ActorState.BeforeBar;
        this.gameStartTime = 0;
        this.score = 0;
        this.time = 30000;
        this.actorPos = 0;
        this.movingDirect = "";
        this.hitCount = 0;
        this.score = 0;
        this.actorPos = 0;
        this.gameend = false;
        this.game_start_page.y = this.ScreenHeight / 2; 
        this.game_control_page.y = this.ScreenHeight / 2; 

        if(flag){
            this.scene.launch("NameScene");
            this.game_start_page.setVisible(false);
        }else{
            this.game_start_page.setVisible(true);
        }

        this.game_control_page.setVisible(false);
        this.game_over_page.setVisible(false);
        this.carHealthGraph.clear();
        this.score_value.setText(this.score);
        this.time_value.setText(30);

        this.car_left.setTexture("CarAtlas", "car_left_0.png");
        this.car_right.setTexture("CarAtlas", "car_right_0.png");
        this.car_left.setVisible(true);
        this.car_right.setVisible(true);
        this.car.setVisible(false);
        this.car.setTexture("CarAtlas", "car_front_0.png");
        this.gameState = CommonDef.GameState.Preparing;
        this.sndbgmusic.play();
    }

    showControls() {
        this.sndclickmenu.play();
        this.tweens.add({
            targets: this.game_start_page,
            y: this.ScreenHeight / 2 * 3,
            duration: 800,
            ease: "Power2"
        });
        this.game_start_page.setVisible(false);
        this.game_control_page.setVisible(true);
        this.gameState = CommonDef.GameState.ShowContols;
    }

    prepareGame() {
        this.sndclickmenu.play();
        for(let i = 0 ; i < this.breakpoints.length ; i ++) {
            this.breakpoints[i].setVisible(true);
        }
        this.game_start_page.setVisible(false);
        this.game_control_page.setVisible(false);
        this.game_over_page.setVisible(false);
        this.gameState = CommonDef.GameState.PickingBar;
        this.start_label.setVisible(true);
        this.actor.anims.playReverse("Smoke");
        setTimeout(() => {
            this.blow.setVisible(true);
            this.blow.anims.play("Blow");
        }, 1500);
        this.bat.setVisible(true);
        this.bat.x = CommonDef.Bat.x;
        this.bat.y = CommonDef.Bat.y;
        this.bat.angle = 150;
        this.actor.x = CommonDef.Actor.x[0];
        this.actor.y = CommonDef.Actor.y[0];
        setTimeout(() => {
            this.sndcheering.play();
            this.startGame();
        }, 2500);
    }
    
    startGame() {
        for(let i = 0 ; i < this.breakpoints.length ; i ++) {
            this.breakpoints[i].setVisible(false);
        }
        this.actorState = CommonDef.ActorState.BeforeBar;
        this.gameStartTime = new Date().getTime();
        this.actor.anims.stop();
        this.tweens.addCounter({
            from: 0,
            to: 999,
            duration: 600,
            onUpdate: (tween) => {
                let value = tween.getValue();
                let index = Math.floor(value / 250) + 1;
                this.actor.setTexture("ActorAtlas", "GRAB" + index + ".png");
                if(index == 3) {
                    this.bat.angle = 0
                    this.bat.x = CommonDef.Bat.x - 10;
                    this.bat.y = CommonDef.Bat.y - 10;
                } else if(index == 4) {
                    this.bat.angle = -20;
                    this.bat.x = CommonDef.Bat.x  - 8;
                    this.bat.y = CommonDef.Bat.y - 22;
                }
            },
            onComplete: () => {
                this.start_label.setVisible(false);
                this.gameState = CommonDef.GameState.Playing;
                this.readyToHit();
            }
        });
        this.sndfight.play();
    }

    readyToHit() {
        this.actorState = CommonDef.ActorState.PickedBar;
        this.actor.anims.play("Actor1");
        this.bat.setVisible(false);
        this.actor.setVisible(true);
        if(this.hitCount >= 70){
            this.gameOver(true);
        }
    }

    move(direct) {
        if(this.actorState == CommonDef.ActorState.Hitting) return;
        let x = this.actor.x;
        let y = this.actor.y;
        switch(direct) {
            case "left":
                x -= (isMobile ? 5 : 3);
                this.actor.setFlipX(true);
            break;
            case "right":
                x += (isMobile ? 5 : 3);
                this.actor.setFlipX(false);
            break;
        }
        if(x < 675 || x > 1245) return;
        if(x > 870 && x < 1095 && y != 670) return;
        this.actor.x = x;
        if(y == 670 && (x < 930 || x > 1055)) {
            this.actorState = CommonDef.ActorState.Jump;
            this.tweens.add({
                targets: this.actor,
                y: 725,
                x: "+=" + (x < 930 ? -90 : 70),
                duration: 200,
                ease: "Linear",
                onComplete: () => {
                    this.actorState = CommonDef.ActorState.PickedBar;
                }
            })
        }
    }

    jump() {
        this.sndjump.play();
        if(this.gameState != CommonDef.GameState.Playing) return;
        if(this.actorState == CommonDef.ActorState.Jump || this.actorState == CommonDef.ActorState.Hitting) return;
        this.actorState = CommonDef.ActorState.Jump;
        this.actor.anims.stop();
        if((this.actor.x >= 800 && this.actor.x <= 870 && !this.actor.flipX  && this.movingDirect == "right" ) || (this.actor.x >= 1060 && this.actor.x <= 1125 && this.actor.flipX  && this.movingDirect == "left" )){
            this.actor.anims.play("Jump");
            let deltaX = 930 - this.actor.x;
            if(this.actor.x >= 1060 && this.actor.x < 1125) deltaX = 1035 - this.actor.x;
            this.tweens.add({
                targets: this.actor,
                y: "-=100",
                x: "+=" + deltaX * 0.7,
                duration: 300,
                ease: "Power2",
                onComplete: () => {
                    this.tweens.add({
                        targets: this.actor,
                        y: 670,
                        x: "+=" + deltaX * 0.3,
                        duration: 300,
                        ease: "Power2",
                        onComplete: () => {
                            this.actor.anims.play("Actor1");
                            this.actorState = CommonDef.ActorState.PickedBar;
                        }
                    })
                }
            })
        } else {
            this.actor.anims.play("Jump");
            if(this.movingDirect == "right"){
                if(this.actor.x < 1240){
                    this.tweens.add({
                        targets: this.actor,
                        x: "+=60",
                        duration: 300,
                        ease: "Power2"
                    })
                }
                this.tweens.add({
                    targets: this.actor,
                    y: "-=100",
                    yoyo: true,
                    duration: 300,
                    ease: "Power2",
                    onComplete: () => {
                        this.actor.anims.play("Actor1");
                        this.actorState = CommonDef.ActorState.PickedBar;
                        if(this.actor.x >= 1060){
                            this.actor.x = 1100;
                            this.actor.y = 725;
                        }
                    }
                })
            }else if(this.movingDirect == "left"){
                if(this.actor.x > 680){
                    this.tweens.add({
                        targets: this.actor,
                        x: "-=60",
                        duration: 300,
                        ease: "Power2"
                    })
                }
                this.tweens.add({
                    targets: this.actor,
                    y: "-=100",
                    yoyo: true,
                    duration: 300,
                    ease: "Power2",
                    onComplete: () => {
                        this.actor.anims.play("Actor1");
                        this.actorState = CommonDef.ActorState.PickedBar;
                    }
                })
            }else{
                this.tweens.add({
                    targets: this.actor,
                    y: "-=100",
                    yoyo: true,
                    duration: 300,
                    ease: "Power2",
                    onComplete: () => {
                        this.actor.anims.play("Actor1");
                        this.actorState = CommonDef.ActorState.PickedBar;
                    }
                })
            }
        }
    }

    isBreakPosition() {
        if(this.actor.y == 725 &&
        ((this.actor.x >= 780 && !this.actor.flipX && this.actor.x <= 870) ||
        (this.actor.x <= 1125 && this.actor.flipX && this.actor.x >= 1095))) return true;
        return false;
    }

    playhitsound(){
        let range = Phaser.Math.Between(1,3);
        switch(range){
            case 1:
                this.sndcarsmash1.play();
            break;
            case 2:
                this.sndcarsmash2.play();
            break;
            case 3:
                this.sndcarsmash3.play();
            break;
        }
    }

    hit(direct) {
        if(this.gameState != CommonDef.GameState.Playing){
            return;
        }
        if(this.actorState != CommonDef.ActorState.PickedBar && this.actorState != CommonDef.ActorState.JumpedCar){
            return;
        }
        if(this.hitCount >= 70){
            return;
        }
        this.actor.anims.stop();
        this.actor.anims.play("Hit");
        this.actorState = CommonDef.ActorState.Hitting;
        setTimeout(() => {
            this.readyToHit();
        }, 750);
        if(!this.isBreakPosition()){
            this.sndjump.play();
            return;
        }else{
            this.playhitsound();
        }
        direct = this.actor.flipX ? "right" : "left";
        if(this.hitCount < 40) {
            if(direct == "left" && Math.floor(this.hitCount / 10) < 3) {
                this.hitCount += 10;
                this.score += 25000;
            }
            if(direct == "right" && this.hitCount % 10 < 3) {
                this.hitCount ++;
                this.score += 25000;
            }
        } else {
            this.hitCount += 10;
            this.score += 25000;
        }
        if(this.hitCount == 33) this.hitCount = 40;
        
        this.score_value.setText(this.score);
        this.carHealthGraph.clear();
        let healthPro = this.hitCount < 40 ? (Math.floor(this.hitCount / 10) + this.hitCount % 10) : 6 + (this.hitCount - 40) / 10;
        this.carHealthGraph.fillRect(0, 0, 173 * (healthPro / 9), 18);
        
        this.tweens.add({
            targets: [this.car, this.car_left, this.car_right],
            x: direct == "left" ? "+=2" : "-=2",
            duration: 200,
            ease: "Power2",
            delay: 200,
            yoyo: true
        });

        this.derbis_sprite.x = this.actor.x + this.actor.width * 0.4
        if(this.actor.flipX){
            this.derbis_sprite.x = this.actor.x - this.actor.width * 0.4
        }
        this.derbis_sprite.y = this.actor.y - this.actor.height * 0.5
        this.derbis_sprite.setFrame('part'+Phaser.Math.Between(1,5)+'.png');
        this.tweens.add({
            targets: [this.derbis_sprite],
            y: "-="+Phaser.Math.Between(50,90),
            x: "+="+Phaser.Math.Between(-5,5),
            angle: Phaser.Math.Between(45,360), 
            duration: 300,
            ease: "Power2",
            delay: 50,
            alpha: 1,
            yoyo: false,
            onComplete: ()=>{
                this.tweens.add({
                    targets: [this.derbis_sprite],
                    y: "+="+Phaser.Math.Between(90,120),
                    x: "+="+Phaser.Math.Between(-30,30),
                    angle: Phaser.Math.Between(45,360), 
                    alpha: 0.8,
                    duration: 300,
                    ease: "Power2",
                    yoyo: false,
                    onComplete: ()=>{
                        this.derbis_sprite.setAlpha(0);
                    }
                })
            }
        })

        setTimeout(() => {
            if(this.hitCount < 40) {
                this.car_left.setTexture("CarAtlas", "car_left_" + Math.floor(this.hitCount / 10) + ".png");
                this.car_right.setTexture("CarAtlas", "car_right_" + (this.hitCount % 10) + ".png");
                this.car_left.setVisible(true);
                this.car_right.setVisible(true);
                this.car.setVisible(false);
            } else {
                this.car_left.setVisible(false);
                this.car_right.setVisible(false);
                this.car.setTexture("CarAtlas", "car_front_" + ((this.hitCount - 40) / 10) + ".png");
                this.car.setVisible(true);
            }
        }, 300);
    }

    gameOver(isWin) {
        if(isWin){
            let currentTime = new Date().getTime();
            let timeleft = (this.time/1000) - Math.floor((currentTime - this.gameStartTime)/1000);
            this.score += (15000 * timeleft);
            this.score_value.setText(this.score);
        }
        if(this.gameState > CommonDef.GameState.Playing) return;
        if(this.gameoverTimer) clearTimeout(this.gameoverTimer);
        this.gameState = CommonDef.GameState.Win + (isWin ? 0 : 2);
        this.actorState = CommonDef.ActorState.GameOver;
        this.gameend = isWin;
        this.sndcheering.play();
        this.shareScore(window.nickname);
        if(isWin) {
            this.actor.anims.stop();
            this.actor.anims.play("Actor1");
            this.game_start_page.setVisible(false);
            this.game_over_page.setVisible(true);
            this.game_over_page.y = 0 // -this.ScreenHeight
            this.game_over_page.setFinalScore(this.score);
            this.sndgameover.play();
            this.gameState = CommonDef.GameState.GameOver
        } else {
            this.actor.anims.stop();
            this.actor.anims.play("Actor1");
            this.game_over_page.setVisible(true);
            this.game_over_page.y = 0; //-this.ScreenHeight
            this.game_over_page.setFinalScore(this.score);
            this.gameState = CommonDef.GameState.GameOver
        }
    }

    openLeaderBoard() {
        this.leaderBoard.setPlayers();
        this.leaderBoard.setVisible(true);
        this.gameNet.getUsers();
    }

    setLeaderBoardPlayers(players) {
        this.leaderBoard.setPlayers(players);
    }

    openSharePage() {
        this.sharePage.nickname_field.setValue("");
        this.sharePage.setVisible(true);
    }

    shareScore(nickname) {
        this.gameNet.createUser(nickname, this.score);
    }

    successCreateUser() {
        this.sharePage.setVisible(false);
        this.openLeaderBoard();
    }

    update() {
        if(this.gameState == CommonDef.GameState.Playing) {
            let currentTime = new Date().getTime();
            let pastTime = currentTime - this.gameStartTime;
            if(pastTime > this.time) {
                this.gameOver(false);
                this.time_value.setText(0);
            } else {
                this.time_value.setText(Math.ceil((30000 - pastTime) / 1000));
            }

            if(isMobile && this.direct_btn_on) {
                let x = this.directBtn_xPos - 330;
                let y = this.directBtn_yPos - 884;
                this.btn_direct.x = CommonDef.btn_direct.x + x / 2.5;
                this.btn_direct.y = CommonDef.btn_direct.y + y / 2.5;
                if(x + y > 0) {
                    if(x - y > 0) {
                        this.movingDirect = "right"
                    } else {
                        //
                    }
                } else {
                    if(x - y > 0  && this.actorPos > -1) {
                        //
                    } else {
                        this.movingDirect = "left"
                    }
                }
            }
            
            if(this.actorState != CommonDef.ActorState.PickedBar) return;
            if(this.movingDirect != "") {
                this.move(this.movingDirect);
                if(this.actor.anims.currentAnim.key != "Walk") {
                    this.actor.anims.stop();
                    this.actor.anims.play("Walk");
                }
            } else {
                if(this.actor.anims.currentAnim.key != "Actor1") {
                    this.actor.anims.stop();
                    this.actor.anims.play("Actor1");
                }
            }

            //Control out of screen
            if(this.actor.x < 675){
                this.actor.x = 675
            }else if(this.actor.x > 1245){
                this.actor.x = 1245
            }
            
        }
    }
}