import { InputField } from "./input";
import { TextButton } from "./textbutton";

export class SharePage extends Phaser.GameObjects.Container {
    constructor(scene) {
        super(scene, 0, 0);
        scene.add.existing(this);
        this.scene = scene;
        this.initGraph();
    }

    initGraph() {
        this.back = this.scene.add.graphics({
            x: 0,
            y: 0,
            fillStyle: {
                color: 0x000000,
                alpha: 1
            }
        });
        this.back.fillRect(0, 0, this.scene.ScreenWidth, this.scene.ScreenHeight);
        this.add(this.back);
        this.title = this.scene.add.sprite(this.scene.ScreenWidth / 2, 420, "BackgroundAtlas", "lb_enter_nickname.png").setOrigin(0.5, 0.5);
        this.add(this.title);
        this.title.setScale(0.5);
        this.description1 = this.scene.add.text(this.scene.ScreenWidth / 2, 480, "OHTER PLAYERS WILL SEE IT", {
            fontSize: 30,
            fontFamily: "GameFont"
        }).setOrigin(0.5, 0.5);
        this.add(this.description1);
        this.description2 = this.scene.add.text(this.scene.ScreenWidth / 2, 520, "ON THE LEADERBOARD", {
            fontSize: 30,
            fontFamily: "GameFont"
        }).setOrigin(0.5, 0.5);
        this.add(this.description2);
        
        this.btn_submit = new TextButton(this.scene, {
            x: this.scene.ScreenWidth / 2,
            y: 650,
            atlas: "BackgroundAtlas",
            normal: "lb_submit.png",
            select: "slb_submit.png",
            onClick: this.onClickSubmit.bind(this)
        });
        this.add(this.btn_submit);

        this.nickname_field = new InputField(this.scene, {
            x: 700,
            y: 560,
            input: {
                x: 0,
                y: 0,
                absolute: {
                    x: 310,
                    y: 590
                },
                width: 520,
                height: 40,
                placeholder: "ENTER NICKNAME HERE",
                color: "#777777",
                fontSize: 24,
                fontFamily: "GameFont"
            },
            align: "center"
        });
        this.add(this.nickname_field);
        this.btn_submit.setScale(0.5)
    }

    onClickSubmit() {
        this.scene.shareScore(this.nickname_field.value);
    }
}